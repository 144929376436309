<script>
</script>

<div id="contact" class="wrapper d-flex justify-content-center align-items-center blue">
  <a class="link" href="mailto:tim.toplak@gmail.com">email</a>
  <a class="link" href="https://github.com/timtoplak">github</a>
  <a class="link" href="https://www.linkedin.com/in/tim-toplak-46912b1a6/">linkedin</a>
</div>

<style>
  .wrapper {
    background-color: #252657;
    min-height: 400px;
  }

  .link {
    margin-left: 20px;
  }
</style>
